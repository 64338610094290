import { ITranslationsResponse } from "../types/ITranslations";

export const fetchTranslationsFile = async (meetingId: string): Promise<ITranslationsResponse> => {
  const response = await fetch(
    `https://${process.env.REACT_APP_STORAGE_NAME}.blob.core.windows.net/gmm2shp-trnsinfo-blob/${meetingId}/translations.json`
  );
  if (!response.ok) {
    throw new Error('Fetch translations response was not ok');
  }
  return response.json();
};
