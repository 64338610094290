import React from 'react';
import {
  AuthProvider as OidcAuthProvider,
  AuthProviderProps as AuthConfig,
  UserManager
} from 'oidc-react';
import { cleanLoginData } from "../../helpers/clearLoginData";

interface AuthProviderProps {
  children: React.ReactNode;
}

// Get the URl and set the clientId dynamically based on that
const url = new URL(window.location.href);

// Check if the path includes "/qr"
if (url.pathname.includes('/qr')) {
  // Extract the query parameters
  const params = new URLSearchParams(url.search);

  // Get the value of the "id" parameter
  const id = params.get("id");

  // Regular expression to validate id
  const regex = /^[A-Z0-9]{10}$/;

  // If the "id" parameter exists, store it in the session storage
  if(id && regex.test(id)) {
    cleanLoginData();
    sessionStorage.setItem('login_method', 'easyLogin');
  }
}

const isEasyLogin = sessionStorage.getItem('login_method') === 'easyLogin';
const clientId = isEasyLogin ? 'easy_login' : 'gvote';
// const responseType = isEasyLogin ? 'token' : 'code';

const userManagerConfig = {
  client_id: clientId,
  redirect_uri: `${window.location.origin}/callback`,
  authority: process.env.REACT_APP_GMM_AUTHORITY!,
}

export const userManager = new UserManager(userManagerConfig);

const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const oidcConfig: AuthConfig = {
    clientId: clientId,
    authority: process.env.REACT_APP_GMM_AUTHORITY,
    responseType: 'code',
    redirectUri: `${window.location.origin}/callback`,
    autoSignIn: false,
    scope:'openid GVOTE:STKE GVOTE:PRXY GVOTE:ADMN GVOTE:GUST',
    extraQueryParams: {"client": "gvote"},
    postLogoutRedirectUri: window.location.origin,
    loadUserInfo: false,
    automaticSilentRenew: true,
    autoSignOutArgs: {
      extraQueryParams: {"client": "gvote"},
      redirectMethod: "replace",
      post_logout_redirect_uri: window.location.origin
    },
  };

  return (
    <OidcAuthProvider {...oidcConfig}>
      {children}
    </OidcAuthProvider>
  );
};

export default AuthProvider;
