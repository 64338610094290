import useAuth from "../auth/oidc-react/useAuth";
import { useEffect, useState } from "react";
import FullscreenSpinner from "../components/loadingScreens/FullscreenSpinner";

export const LoginPage = () => {
  const { user, signIn } = useAuth();
  const [signInError, setSignInError] = useState<Error | null>(null);

  useEffect(() => {
    const initiateSignIn = async () => {
      try {
        if (!user) {
          await signIn();
        }
      } catch (error) {
        setSignInError(error as Error);
      }
    };

    initiateSignIn();
  }, [signIn, user]);

  if (signInError) {
    return <FullscreenSpinner error={signInError.message} />
  }

  return (
    <></>
  );
};
