import { Reducer } from 'redux';
import { stepByStepTypes } from './stepByStepTypes';
import { IPXYResponse } from '../../types/ipxyResolutions';

const initialStepByStepState = {
  currentStep: '',
  econf: '', // electronic admission card
  econfEmail: '',
  isEconfEmailValid: false,
  econfVerifiedEmail: '',
  isHistoryPresent: false,
  additionalRequests: {
    rspncd: 'ADRQ',
    addreq: [],
  },
  responseType: '',
  iProxyResolutions: {
    adhoc: '',
    rspncd: 'IPXY',
    votperrsln: [],
  },
  shareholderProxy: {
    formIsValid: false,
    rspncd: 'SPXY',
    formValues: {
      representedBy: 'INDV',
    },
  },
};

const stepByStepReducer: Reducer<any, any> = (
  state = initialStepByStepState,
  action
) => {
  switch (action.type) {
    case stepByStepTypes.SET_CURRENT_STEP:
      return {
        ...state,
        currentStep: action.payload,
      };
    case stepByStepTypes.SET_ECONF:
      return {
        ...state,
        econf: action.payload,
      };
    case stepByStepTypes.ADD_ECONF_EMAIL:
      return {
        ...state,
        econfEmail: action.payload,
      };
    case stepByStepTypes.ECONF_EMAIL_VALID:
      return {
        ...state,
        isEconfEmailValid: action.payload,
      };
    case stepByStepTypes.ADD_ECONF_VERIFIED_EMAIL:
      return {
        ...state,
        econfVerifiedEmail: state.econfEmail || action.payload,
      };
    case stepByStepTypes.SET_ADDITIONAL_REQUESTS:
      return {
        ...state,
        additionalRequests: {
          ...state.additionalRequests,
          addreq: action.payload,
        },
      };
    case stepByStepTypes.SET_RESPONSE_TYPE:
      return {
        ...state,
        responseType: action.payload,
      };
    case stepByStepTypes.SET_IPROXY_RESOLUTIONS:
      const { votperrsln } = action.payload as IPXYResponse;
      let newPayload;

      if (votperrsln?.some((e) => typeof e.rslnseq === 'number')) {
        const sortedVotperrsln = votperrsln
          .slice()
          .sort(({ rslnseq: a }, { rslnseq: b }) => (!a || !b ? 0 : a - b));
        newPayload = {
          ...action.payload,
          votperrsln: [...sortedVotperrsln],
        };
      }

      let updatedNewPayload = {...newPayload}

      // Check if the oldKey exists in the original object
      if (newPayload && 'rsptyp' in newPayload) {
        const {rsptyp, ...rest} = newPayload;
        updatedNewPayload = {rspncd: rsptyp, ...rest};
      }

      return {
        ...state,
        iProxyResolutions: updatedNewPayload || {
          ...state.iProxyResolutions,
          ...action.payload,
        },
      };
    case stepByStepTypes.SET_SHAREHOLDER_PROXY_DATA:
      return {
        ...state,
        shareholderProxy: {
          ...state.shareholderProxy,
          formValues: action.payload,
        },
      };
    case stepByStepTypes.SET_SHAREHOLDER_FORM_VALIDATION:
      return {
        ...state,
        shareholderProxy: {
          ...state.shareholderProxy,
          formIsValid: action.payload,
        },
      };
    case stepByStepTypes.SET_IS_HISTORY_PRESENT:
      return {
        ...state,
        isHistoryPresent: action.payload,
      };
    default:
      return state;
  }
};

export default stepByStepReducer;
