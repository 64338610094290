import { IMeetingDetails } from "../interfaces";

export const fetchMeetingDetails = async (token: string):  Promise<IMeetingDetails | null> => {
  const url = `${process.env.REACT_APP_GMM_API_BASE_URL}${process.env.REACT_APP_GMM_API_INFO}${process.env.REACT_APP_GMM_API_MEETINGDETAILS}`;
  const response = await fetch(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  if (response.ok) return response.json();
  console.error('Error fetching meeting details.');
  return null;
};
