import { useQuery } from "@tanstack/react-query";
import useAuth from "../auth/oidc-react/useAuth";
import { fetchTermsAndConditionsStatus } from "../api/fetchTermsAndConditionsStatus";
import { getFromSessionStorage } from "../helpers/getFromSessionStorage";
import {
  validateAndSanitizeBoolean
} from "../helpers/validateAndSanitizeBoolean";
import { ITnCResponse } from "../interfaces";


const useTermsAndConditionsCheck = (virtTnCRequired?: boolean) => {
  const { isGvoteTermsAndConditionsAccepted, isEQTermsAndConditionsAccepted, getAccessToken, user, stkeId,  } = useAuth();
  const sessionStorageTnC = getFromSessionStorage<boolean>(`TC-${stkeId}`, validateAndSanitizeBoolean) ?? false;
  const sessionStorageVTnC = getFromSessionStorage<boolean>(`VTC-${stkeId}`, validateAndSanitizeBoolean) ?? false;

  const { data, isLoading, isError } = useQuery<ITnCResponse, Error>(
    ['termsAndConditionsStatus', stkeId],
    async () => {
      const accessToken = await getAccessToken();
      if (!accessToken) {
        throw new Error('No access token for terms and conditions status call.');
      }

      return fetchTermsAndConditionsStatus(accessToken);
    },
    {
      enabled: (!!user && !!stkeId) && ((!isGvoteTermsAndConditionsAccepted) || (!isEQTermsAndConditionsAccepted && virtTnCRequired)),
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      staleTime: 5 * 60 * 1000, // Data is considered fresh for 5 minutes
      cacheTime: 30 * 60 * 1000, // Data is cached for 30 minutes
    }
  );

  // Set manually the loading state since we won't fetch the api
  const effectiveLoading = (isGvoteTermsAndConditionsAccepted || (isEQTermsAndConditionsAccepted && virtTnCRequired)) ? false : isLoading;
  // Map the API response to your specific return values
  const isGvoteTnCAgreed = isGvoteTermsAndConditionsAccepted ||
    sessionStorageTnC || (data?.tncacpt === 'Y');
  const isEQTnCAgreed = isEQTermsAndConditionsAccepted || sessionStorageVTnC || (data?.vrttnc === 'Y');

  return {
    isGvoteTnCAgreed,
    isEQTnCAgreed,
    isLoading: effectiveLoading,
    isError
  };
};

export default useTermsAndConditionsCheck;
