import React, { useEffect, useState } from 'react';
import OutlinedFlagRoundedIcon from '@mui/icons-material/OutlinedFlagRounded';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import useLangSelectorStyles from './languageSelectorStyles';
import clsx from 'clsx';
import { useMeetingDetails } from "../../hooks/useMeetingDetails";
import { useTranslation } from "react-i18next";

interface Props {
  toolbar?: (toolbar: boolean) => void;
}

// don't forget to update 'src/i18n.ts'
const defaultLangs = [
  {
    name: 'English',
    lng: 'en',
  },
  {
    name: 'Deutsch',
    lng: 'de',
  },
  {
    name: 'Italiano',
    lng: 'it',
  },
  {
    name: 'Français',
    lng: 'fr',
  },
];

function LanguageSelector({ toolbar }: Readonly<Props>) {
  const selectorClasses = useLangSelectorStyles();

  const { i18n } = useTranslation();
  const langShort = i18n.language;

  const { data: meetingDetails } = useMeetingDetails();
  const companyLangs = meetingDetails?.cmpylang;

  const [availableLanguages, setAvailableLanguages] = useState<{ name: string; lng: string }[]>([]);

  let usedLanguages: { name: string; lng: string }[] = [];

  const changeLanguage = (lng: string) => {
    i18n.changeLanguage(lng);
    localStorage.setItem('userLanguage', lng);
  };

  // Create buttons from available languages
  useEffect(() => {
    /*
     * if company languages are available create new array from default
     * languages, based on company languages
     * */
    if (companyLangs?.length) {
      companyLangs.forEach((item: string) => {
        return usedLanguages.push(
          // @ts-ignore
          defaultLangs.find((lang) => lang.lng === item?.toLowerCase())
        );
      });

      setAvailableLanguages(usedLanguages);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyLangs]);

  // useEffect(() => {
  //   // app has user selected language
  //   if (userSelectedLanguage && currentLanguage && (companyLangs && companyLangs?.length)) {
  //     if (companyLangs.includes(currentLanguage?.toUpperCase())) {
  //       // user selected language is available
  //       dispatch(setLanguage(currentLanguage));
  //     } else {
  //       // user selected language is NOT available, switching to profile language
  //       changeLanguage(profileLanguage, 'automatic');
  //       i18n.changeLanguage(profileLanguage);
  //     }
  //   } else {
  //     // user has no defined language
  //     if ((companyLangs && companyLangs?.length) && companyLangs.includes(profileLanguage?.toUpperCase())) {
  //       // profile language available in company languages
  //       changeLanguage(profileLanguage, 'automatic')
  //       i18n.changeLanguage(profileLanguage);
  //     } else {
  //       // profile language is NOT available in company languages, set language to first company language
  //         changeLanguage(usedLanguages[0]?.lng.toLowerCase() ?? 'de', 'automatic');
  //       i18n.changeLanguage(usedLanguages[0]?.lng.toLowerCase() ?? 'de');
  //     }
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [userSelectedLanguage]);

  const langButtons = availableLanguages.map(
    (button: { name: string; lng: string }) => (
      <Button
        key={`${button.lng}`}
        color="inherit"
        onClick={() => {
          changeLanguage(button.lng);
          toolbar && toolbar(false);
        }}
        startIcon={<OutlinedFlagRoundedIcon />}
        className={clsx(selectorClasses.flagDefault, {
          [selectorClasses.flagActive]: langShort === `${button.lng}`,
        })}
        classes={{ root: selectorClasses.label }}
      >
        {button.name}
      </Button>
    )
  );

  return (
    <Box
      display="flex"
      flexDirection="column"
      my={3}
      color="var(--secondary-color)"
    >
      {langButtons ? langButtons : null}
    </Box>
  );
}

export default LanguageSelector;
