import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { ITranslation } from "./types/ITranslations";

// Type for i18next resources
export type I18NextResource = {
  [lang: string]: { translation: ITranslation };
};

i18n
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: false,

    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    }
  });

export default i18n;
