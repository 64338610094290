import { useCallback, useContext, useRef, useEffect } from 'react';
import { AuthContext, AuthContextProps } from 'oidc-react';
import { oidcReactCustomClaims } from "./oidcReactCustomClaims";
import { cleanLoginData } from "../../helpers/clearLoginData";

interface Authentication {
  signIn: AuthContextProps['signIn'];
  signOut: AuthContextProps['signOut'];
  signOutRedirect: AuthContextProps['signOutRedirect'];
  user: AuthContextProps['userData'];
  isLoading: AuthContextProps['isLoading'];
  email: string | undefined;
  mtngId: string | undefined;
  role: string | undefined;
  userLang: string;
  userLangNoFallback: string | null;
  stkeId: string | undefined;
  getAccessToken: () => Promise<string | null>;
  isGvoteTermsAndConditionsAccepted: boolean;
  isEQTermsAndConditionsAccepted: boolean;
  isShareholder: boolean;
  isAdmin: boolean;
  isGuest: boolean;
  isProxy: boolean;
}

const useAuthentication = (): Authentication => {
  const authContext = useContext(AuthContext);

  if (!authContext) {
    throw new Error('useAuthentication must be used within an AuthProvider');
  }

  const customClaims = oidcReactCustomClaims(authContext);
// Reference to store the current token retrieval promise
  const tokenPromiseRef = useRef<Promise<string | null> | null>(null);

// Reference to store the resolve function of the current token retrieval promise
  const resolveTokenRef = useRef<((token: string | null) => void) | null>(null);

// useEffect to watch for changes in authContext.userData
// When authContext.userData changes and resolveTokenRef.current is set, resolve the token promise
  useEffect(() => {
    if (authContext.userData && resolveTokenRef.current) {
      // console.log('Token found:', authContext.userData.access_token);
      resolveTokenRef.current(authContext.userData.access_token);
      resolveTokenRef.current = null; // Reset the resolve function reference
      tokenPromiseRef.current = null; // Reset the token promise reference
    }
  }, [authContext.userData]);

// Function to get the access token, using useCallback to memoize the function
  const getAccessToken = useCallback(() => {
    // console.log('getAccessToken called');

    // If the token is already available in authContext.userData, return it immediately
    if (authContext.userData) {
      // console.log('Token already available:', authContext.userData.access_token);
      return Promise.resolve(authContext.userData.access_token);
    }

    // If there's no ongoing token retrieval process, create a new promise
    if (!tokenPromiseRef.current) {
      tokenPromiseRef.current = new Promise<string | null>((resolve, reject) => {
        // Store the resolve function so it can be called later when the token is available
        resolveTokenRef.current = resolve;

        // Set a timeout to reject the promise if the token is not available within 5000 ms
        setTimeout(() => {
          if (tokenPromiseRef.current) {
            reject('Waiting timeout exceeded for token.');
            resolveTokenRef.current = null; // Reset the resolve function reference
            tokenPromiseRef.current = null; // Reset the token promise reference
          }
        }, 5000); // Timeout duration in ms
      });
    }

    // Return the current token retrieval promise
    return tokenPromiseRef.current;
  }, [authContext.userData]);

  const customSignOut = useCallback(async () => {
    try {
      cleanLoginData();
      await authContext.signOut();
      window.location.reload();
    } catch (error) {
      // console.error('Error during custom sign out:', error);
    }
  }, [authContext]);

  const customSignOutRedirect = useCallback(async () => {
    try {
      sessionStorage.removeItem('login_method');
      await authContext.signOutRedirect({
        post_logout_redirect_uri: window.location.origin,
      });
    } catch (error) {
      // console.error('Error during custom sign out redirect:', error);
    }
  }, [authContext]);

  const proxyRoles = ['IPXY', 'CPXY', 'SPXY'];

  const isShareholder = customClaims?.role === 'STKE';
  const isAdmin = customClaims?.role === 'ADMN';
  const isGuest = customClaims?.role === 'GUST';
  const isProxy = proxyRoles.includes(customClaims?.role ?? '');

  return {
    signIn: authContext.signIn,
    signOut: customSignOut,
    signOutRedirect: customSignOutRedirect,
    user: authContext.userData,
    isLoading: authContext.isLoading,
    email: authContext.userData?.profile?.email,
    mtngId: customClaims?.mtngid,
    role: customClaims?.role,
    userLang: customClaims?.stkelng?.toLowerCase() ?? 'de',
    userLangNoFallback: customClaims?.stkelng?.toLowerCase() ?? null,
    stkeId: customClaims?.stkeid,
    isGvoteTermsAndConditionsAccepted: customClaims?.tncacpt === 'Y',
    isEQTermsAndConditionsAccepted: customClaims?.vrttnc === 'Y',
    isShareholder: isShareholder,
    isAdmin: isAdmin,
    isGuest: isGuest,
    isProxy: isProxy,
    getAccessToken,
  };
};

export default useAuthentication;
