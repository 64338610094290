/* eslint-disable no-dupe-keys */
import { Theme } from '@mui/material/styles';

import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

const useCommonPageStyles = makeStyles((theme: Theme) =>
  createStyles({
    pageWrapper: {
      flex: 1,
    },
    // loader: {
    //   position: 'fixed',
    //   bottom: 0,
    //   right: 0,
    //   left: 0,
    // },
    // background: {
    //   height: '100vh',
    //   overflow: 'scroll',
    //   flex: 1,
    //   paddingTop: 56,
    //   marginTop: -56,
    //   backgroundSize: 'cover',
    //   backgroundPosition: 'center',
    //   backgroundAttachment: 'fixed',
    //   // [theme.breakpoints.up('sm')]: {
    //   //   paddingTop: 64,
    //   //   marginTop: -64,
    //   // },
    // },
    bottomBarContainer: {
      position: 'fixed',
      bottom: 0,
      right: 0,
      left: 0,
      zIndex: 1,
    },
    bottomBar: {
      justifyContent: 'space-between',
      [theme.breakpoints.up('sm')]: {
        justifyContent: 'flex-end',
        '& a:not(:nth-last-child(1)) ': {
          marginRight: theme.spacing(2),
        },
      },
      [theme.breakpoints.down(550)]: {
        flexDirection: 'column',
        '& a ': {
          marginBottom: 10,
        },
      },
    },
    main: {
      // display: 'flex',
      // flexDirection: 'column',
      // overflow: 'hidden',
      // height: '100vh',
      // @ts-ignore
      // height: `calc(var(--vh, 1vh) * 100)`,
      flexGrow: 1,
      minHeight: `calc(var(--vh, 1vh) * 85 - 64px)`,
      // overflow: 'auto',
      scrollbarGutter: 'stable',
    },
    content: {
      // height: '100%',
      // overflow: 'auto',
      // position: 'relative',
    },
    firstPage: {
      display: 'flex !important',
      // flexDirection: 'column',
      // height: '100%',
      minHeight: `calc(var(--vh, 1vh) * 75 - 64px)`,
      // overflowY: 'auto',
      [theme.breakpoints.down('sm')]: {
        minHeight: `calc(var(--vh, 1vh) * 100 - 64px)`,
      },
    },
    footerPadding: {
      paddingBottom: theme.spacing(14),
    },
    restPage: {
      // height: 'auto !important',
      // paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(2),
    },
    bgColor: {
      backgroundColor: theme.palette.grey[50],
    },
    toolbar: theme.mixins.toolbar,
    fabButtonContainer: {

    },
  })
);

export default useCommonPageStyles;
