import { IPXYResolutions } from "../types/ipxyResolutions";

export const fetchResolutions = async (token: string):  Promise<IPXYResolutions | null> => {
  const url = `${process.env.REACT_APP_GMM_API_BASE_URL}${process.env.REACT_APP_GMM_API_INFO}${process.env.REACT_APP_GMM_API_RESOLUTIONS}`;
  const response = await fetch(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  if (response.ok) return response.json();
  console.error('Error fetching resolutions.');
  return null;
};
