import { useQuery } from '@tanstack/react-query';
import useAuthentication from '../auth/oidc-react/useAuth';
import { fetchTranslationsFile } from "../api/fetchTranslationsFile";
import i18n from "i18next";
import { I18NextResource } from "../i18n";

export const useTranslationFiles = () => {
  const { mtngId } = useAuthentication();

  return useQuery<I18NextResource, Error>(
    ['translations', mtngId],
    async () => {
      if (!mtngId) {
        throw new Error('No access token or user identifier');
      }
      const translationsResponse = await fetchTranslationsFile(mtngId);
      const translations = translationsResponse.translations;

      // Map the translations to the format expected by i18next
      const i18nextResources: I18NextResource = {};
      Object.keys(translations).forEach(key => {
        // Extract the language code from the key (assuming it starts with 'lang')
        if (key.startsWith('lang')) {
          // Convert keys like 'langen' to 'en'
          const lang = key.substring(4); // Removes 'lang' prefix
          i18nextResources[lang] = { translation: translations[key] };
        }
      });

      return i18nextResources;
    },
    {
      enabled: !!mtngId,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      staleTime: 10 * 60 * 1000, // Data is considered fresh for 5 minutes
      cacheTime: 10 * 60 * 60 * 1000, // Data is cached for 24 hours
      onSuccess: (i18nextResources) => {
        // Initialize or update i18next with the loaded translations
        Object.keys(i18nextResources).forEach((lang) => {
          i18n.addResourceBundle(lang, 'translation', i18nextResources[lang].translation, true, true);
        });
      },
    }
  );
};
