import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import useAuth from "../auth/oidc-react/useAuth";
import FullscreenSpinner from "../components/loadingScreens/FullscreenSpinner";

const Callback: React.FC = () => {
  const auth = useAuth();
  const history = useHistory();

  useEffect(() => {
    if (!auth.isLoading) {
      // Authentication process completed, redirect to the protected route
      history.push('/');
    }
  }, [auth, history]);

  return (
    <FullscreenSpinner />  // Display a loading spinner
  );
};

export default Callback;
