const isDevelopment = process.env.REACT_APP_ENV === 'development';


export const fetchQrLoginToken = async (hash: string):  Promise<{} | null> => {
  const url = `${process.env.REACT_APP_GMM_API_BASE_URL}${process.env.REACT_APP_QR_LOGIN_ENDPOINT}${hash}`;
  const response = await fetch(url,
    {
      headers: {
        ...(isDevelopment && { 'X-Env-Identifier': 'gvote' }) // Add only in development
      },
    });

  if (!response.ok) throw new Error('QR login request failed.')

  return response.json();
};
