import React, { FC } from 'react';
import { styled } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { SnackbarProvider, MaterialDesignContent } from 'notistack';

const useStyles = makeStyles(({ palette }) => ({
  success: {
    backgroundColor: `${palette.success.main} !important`,
  },
  error: {
    backgroundColor: `${palette.error.main} !important`,
  },
}));

export const NotistackProvider: FC = ({ children }) => {
  const classes = useStyles();

  const StyledMaterialDesignContent = styled(MaterialDesignContent)(() => ({
    '&.notistack-MuiContent-success': {
      backgroundColor: classes.success,
    },
    '&.notistack-MuiContent-error': {
      backgroundColor: classes.error,
    },
  }));

  return (
    <SnackbarProvider
      Components={{
        success: StyledMaterialDesignContent,
        error: StyledMaterialDesignContent
      }}
      children={children}
      preventDuplicate
      style={{ borderRadius: '12px' }}
      anchorOrigin={{
        horizontal: 'center',
        vertical: 'top',
      }}
    />
  );
};
