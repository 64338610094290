import useAuth from "../auth/oidc-react/useAuth";

const useRoleCheck = () => {
 const { isAdmin, isGuest, isProxy, isShareholder } = useAuth();

  const isRoleValid = isShareholder || isAdmin || isProxy || isGuest;

  return { isRoleValid };
};

export default useRoleCheck;
