import { fetchCurrentDateTime } from "../api/fetchCurrentDateTime";
import { useEffect, useState, useCallback } from "react";
import useAuth from "../auth/oidc-react/useAuth";

let cache: number | null = null;
let lastFetch = 0;

export const useFetchCurrentTime = () => {
  const [currentTime, setCurrentTime] = useState<number | undefined>(
    undefined
  );
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null); // Expecting string or null for error
  const { getAccessToken } = useAuth();

  const getTime = useCallback(async (force = false) => {
    setIsLoading(true);
    const token = await getAccessToken();
    return new Promise(async (resolve, reject) => {
      try {
        if (!force && cache !== null && Date.now() - lastFetch < 5000) {
          setCurrentTime(cache);
          setIsLoading(false);
          resolve(cache);
          return;
        }

        if (token) {
          const time = await fetchCurrentDateTime(token);
          cache = time;
          lastFetch = Date.now();
          setCurrentTime(time);
          resolve(time);
        } else {
          throw new Error("Missing access token.");
        }
      } catch (err) {
        console.log(err)
        const errorMessage = err instanceof Error ? err.message : "An unknown error occurred fetching time";
        setError(errorMessage);
        reject(errorMessage);
      } finally {
        setIsLoading(false);
      }
    });
  }, [getAccessToken]);

  useEffect(() => {
    getTime();
  }, [getTime]);

  const refetchCurrentTime = useCallback(() => {
    // console.info("Re-fetching current time");
    return getTime(true);
  }, [getTime]);

  return { currentTime, isLoading, error, refetchCurrentTime };
};
