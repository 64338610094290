import React from 'react';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import { LinkProps as RouterLinkProps, useLocation } from 'react-router-dom';
import { DistributiveOmit } from '@mui/types';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { HashLink as NavLink } from 'react-router-hash-link';

interface ListItemLinkProps {
  icon?: React.ReactElement;
  primary: string;
  to: string;
  toolbar?: any;
}

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      '&$selected': {
        textDecoration: 'underline',
        backgroundColor: 'transparent',
      },
    },
    selected: {},
    menuLink: {
      fontSize: 'inherit',
      color: 'var(--secondary-color)',
      fontWeight: 700,
      paddingLeft: 64,
    },
  })
);

function ListItemLink(props: ListItemLinkProps) {
  const { icon, primary, to, toolbar } = props;
  const { pathname } = useLocation();
  const classes = useStyles();

  function handleNavClick() {
    toolbar && toolbar(false);
  }

  const renderLink = React.useMemo(
    () =>
      React.forwardRef<any, DistributiveOmit<RouterLinkProps, 'to'>>((itemProps, ref) => (
        <NavLink
          to={to}
          ref={ref}
          {...itemProps}
          scroll={(element) => element.scrollIntoView({ behavior: 'smooth' })}
        />
      )),
    [to]
  );

  return (
    <>
      {primary && (
        <li>
          <ListItem
            button
            component={renderLink}
            selected={to === pathname}
            classes={{ root: classes.root, selected: classes.selected }}
            onClick={handleNavClick}
          >
            {icon ? <ListItemIcon>{icon}</ListItemIcon> : null}
            <ListItemText
              primary={primary}
              classes={{ primary: classes.menuLink }}
            />
          </ListItem>
        </li>
      )}
    </>
  );
}

export default ListItemLink;
