export const stepByStepTypes = {
  SET_CURRENT_STEP: 'SET_CURRENT_STEP',
  SET_ECONF: 'SET_ECONF',
  ADD_ECONF_EMAIL: 'ADD_ECONF_EMAIL',
  ECONF_EMAIL_VALID: 'ECONF_EMAIL_VALID',
  ADD_ECONF_VERIFIED_EMAIL: 'ADD_ECONF_VERIFIED_EMAIL',
  SET_ADDITIONAL_REQUESTS: 'SET_ADDITIONAL_REQUESTS',
  SET_RESPONSE_TYPE: 'SET_RESPONSE_TYPE',
  SET_IPROXY_RESOLUTIONS: 'SET_IPROXY_RESOLUTIONS',
  SET_SHAREHOLDER_PROXY_DATA: 'SET_SHAREHOLDER_PROXY_DATA',
  SET_SHAREHOLDER_FORM_VALIDATION: 'SET_SHAREHOLDER_FORM_VALIDATION',
  SET_IS_HISTORY_PRESENT: 'SET_IS_HISTORY_PRESENT',
};
