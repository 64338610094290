interface IDateTimeResponse {
  epochDateTime: number;
}

export async function fetchCurrentDateTime(token: string): Promise<number> {
  try {
    const response = await fetch(`${process.env.REACT_APP_GMM_API_BASE_URL}${process.env.REACT_APP_GMM_API_TIME}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      }
    });

    if (!response.ok) {
      throw new Error(`HTTP error, getting current time from the server! status: ${response.status}`);
    }

    const dateTimeData = await response.json() as IDateTimeResponse;

    // Validate that the response has the correct structure
    if (typeof dateTimeData?.epochDateTime === 'number') {
      return dateTimeData.epochDateTime;
    } else {
      // Handle unexpected data format
      // console.error('Invalid response format for current time', dateTimeData);
      throw new Error(`HTTP error, getting current time from the server! status: ${response.status}`);
    }

  } catch (error) {
    // Log the error for debugging purposes
    console.error('Error fetching current time:', error);
    throw error;
  }
}
