import React, { lazy, ReactNode, useState } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Drawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItemLink from '../listItemLink';
import Divider from '@mui/material/Divider';
import LanguageSelector from '../languageSelector';
import { Button, Container, Tooltip } from '@mui/material';
import ExitToAppRoundedIcon from '@mui/icons-material/ExitToAppRounded';
import HistoryRoundedIcon from '@mui/icons-material/HistoryRounded';
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded';
import SettingsRoundedIcon from '@mui/icons-material/SettingsRounded';
import FontLoader from '../fontLoader';
import Footer from '../footer';
import Grid from '@mui/material/Grid';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import useCommonPageStyles from '../../pages/commonPageStyles';
import Typography from '@mui/material/Typography';
import clsx from 'clsx';
import useAuth from "../../auth/oidc-react/useAuth";
import { useLogo } from "../../hooks/useLogo";
import { useBackgroundImage } from "../../hooks/useBackgroundImage";
import { useMeetingDetails } from "../../hooks/useMeetingDetails";
import { useTranslation } from "react-i18next";

const Activities = lazy(() => import('../activities'));
const ShareholderInformation = lazy(() => import('./ShareholderInformation'));

const drawerWidth = 320;

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    display: 'flex',
  },
  appBar: {
    backgroundColor: '#fff',
  },
  menuButton: {
    color: 'var(--primary-color)',
  },
  logoutButtonRoot: {
    width: '100%',
  },
  logoutButtonLabel: {
    justifyContent: 'unset', paddingLeft: theme.spacing(8),
  }, // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  toolbarLogo: { maxWidth: 110, maxHeight: 50, display: 'flex' },
  drawerPaper: {
    width: drawerWidth,
    borderLeft: 'none',
    borderTopLeftRadius: 30,
    borderBottomLeftRadius: 30,
  },
}));

interface Props {
  children: ReactNode;
}

function AppBarWrapper({ children }: Readonly<Props>) {
  const classes = useStyles();
  const pageClasses = useCommonPageStyles();

  const location = useLocation();
  const auth = useAuth();
  const { mtngId, signOutRedirect } = auth;
  const { t } = useTranslation();

  const { data: meetingDetails } = useMeetingDetails();

  const publications = meetingDetails?.publications?.length;
  const isInvitationEmail = meetingDetails?.emilreq;
  const additionalRequestsData = meetingDetails?.additionalrequest;
  const isShareholderDetailsEnabled = meetingDetails?.showstkdtl === 'Y';

  const [mobileOpen, setMobileOpen] = useState(false);
  const [activitiesOpen, setActivitiesOpen] = useState(false);

  const { logoUrl } = useLogo();
  const { backgroundUrl } = useBackgroundImage();

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleActivitiesToggle = () => {
    setActivitiesOpen(!activitiesOpen);
  };

  const drawer = (<Box display="flex" flexDirection="column" height={1}>
      <Box m={2} display="flex" justifyContent="flex-end">
        <IconButton
          aria-label="open drawer"
          edge="end"
          onClick={handleDrawerToggle}
          className={classes.menuButton}
          size="large">
          <ChevronRightRoundedIcon />
        </IconButton>
      </Box>

      {auth.user &&

        <>
          <Divider />

          {isShareholderDetailsEnabled && <>
            <ShareholderInformation isOpen={mobileOpen} />
            <Divider />
          </>}

          <Box fontSize="h1.fontSize">
            <List>
              <ListItemLink
                primary={t('menu.event')}
                to="/#event"
                toolbar={setMobileOpen}
              />
              <ListItemLink
                primary={t('menu.agenda')}
                to="/#agenda"
                toolbar={setMobileOpen}
              />
              {publications && (<ListItemLink
                  primary={t('menu.publications')}
                  to="/#publications"
                  toolbar={setMobileOpen}
                />)}
            </List>
          </Box>

          <Divider />
        </>}


      <LanguageSelector toolbar={setMobileOpen} />

      <Divider />

      {(auth.user) && <Box my={3}>
        {auth.isShareholder && (isInvitationEmail === 'Y' || (additionalRequestsData && additionalRequestsData?.length > 0)) && (
          <Button
            onClick={handleDrawerToggle}
            component={RouterLink}
            to="/settings"
            color="inherit"
            startIcon={<SettingsRoundedIcon />}
            classes={{
              root: clsx(classes.logoutButtonRoot, classes.logoutButtonLabel),
            }}
          >
            {t('button.settings')}
          </Button>)}

        <Button
          onClick={() => {
            signOutRedirect();
            sessionStorage.removeItem('TC');
          }}
          color="inherit"
          startIcon={<ExitToAppRoundedIcon />}
          classes={{
            root: clsx(classes.logoutButtonRoot, classes.logoutButtonLabel),
          }}
        >
          {t('button.logout')}
        </Button>
      </Box>}

      <Divider />
    </Box>);

  const activities = (<Box display="flex" flexDirection="column" height={1}>
      <Box m={2} display="flex" justifyContent="flex-end">
        <IconButton
          aria-label="open drawer"
          edge="end"
          onClick={handleActivitiesToggle}
          className={classes.menuButton}
          size="large">
          <ChevronRightRoundedIcon />
        </IconButton>
      </Box>

      <Divider />

      {auth.user &&
        <Box m={2} pb={2} display="flex" flexDirection="column" height={1}>
          <Typography variant="h1" gutterBottom>
            {t('title.activity_timeline')}
          </Typography>
          <Typography gutterBottom>
            {t('text.activities_desc')}
          </Typography>
          <Activities isOpen={activitiesOpen} />
        </Box>}
    </Box>);

  // check if background image is required for the given 'page'
  const isBackground = (): boolean => {
    const pathWithBackground = ['/', '/thank-you', '/admission', '/proxy',];
    return pathWithBackground.includes(location.pathname);
  };

  const useBgStyles = makeStyles(() => createStyles({
    bg: {
      '&::after': {
        content: `''`,
        position: 'fixed' /* stretch a fixed position to the whole screen */,
        top: 0,
        height: '100vh' /* fix for mobile browser address bar appearing disappearing */,
        left: 0,
        right: 0,
        zIndex: '-1' /* needed to keep in the background */,
        backgroundImage: `url(${backgroundUrl})`,
        backgroundSize: isBackground() ? 'cover' : `0 0`,
        backgroundPosition: 'center center',
        backgroundRepeat: 'no-repeat',
      },
    },
  }));
  const bgClasses = useBgStyles();

  return <>
    {mtngId && <FontLoader folderId={mtngId} />}
    <AppBar
      position="fixed"
      className={classes.appBar}
      elevation={2}
      color="inherit"
    >
      <Toolbar>
        <Container>
          <Grid container wrap="nowrap">
            <Box width={1} display="flex" alignItems="center">
              {logoUrl && (
                <RouterLink to="/" style={{ display: 'inline-block' }}>
                  <img
                    src={logoUrl}
                    alt="logo"
                    className={classes.toolbarLogo}
                  />
                </RouterLink>)}
            </Box>
            <Tooltip
              title={t('title.activity_timeline')}
            >
              <IconButton
                aria-label="open drawer"
                edge="end"
                onClick={handleActivitiesToggle}
                className={classes.menuButton}
                style={{ marginRight: '8px' }}
                size="large">
                <HistoryRoundedIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title={t('button.settings')}>
              <IconButton
                aria-label="open drawer"
                edge="end"
                onClick={handleDrawerToggle}
                className={classes.menuButton}
                size="large">
                <MenuIcon />
              </IconButton>
            </Tooltip>
          </Grid>
        </Container>
      </Toolbar>
    </AppBar>
    <main
      className={clsx(pageClasses.main, { [bgClasses.bg]: isBackground() })}
    >
      <div className={classes.toolbar} />
      {children}
    </main>

    <Footer extendedFooter={location.pathname === '/'} />

    <nav aria-label="app menu">
      <Drawer
        variant="temporary"
        anchor="right"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        classes={{
          paper: classes.drawerPaper,
        }}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
      >
        {drawer}
      </Drawer>
      <Drawer
        variant="temporary"
        anchor="right"
        open={activitiesOpen}
        onClose={handleActivitiesToggle}
        classes={{
          paper: classes.drawerPaper,
        }}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
      >
        {activities}
      </Drawer>
    </nav>
  </>;
}

export default AppBarWrapper;
