import { useQuery } from "@tanstack/react-query";
import useAuthentication from "../auth/oidc-react/useAuth";
import { fetchLogo } from "../api/fetchLogo";

export const useLogo = () => {
  const { mtngId } = useAuthentication();

  const { data, isLoading, isError, error } = useQuery<string | null>(
    ['logo', mtngId],
    async () => {
      if (mtngId) {
        return fetchLogo(mtngId);
      } else {
        // Handle the case where the token is undefined
        throw new Error('No meeting-id for logo call.');
      }
    }, {
      enabled: !!mtngId,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      staleTime: 5 * 60 * 1000, // Data is considered fresh for 5 minutes
      cacheTime: 24 * 60 * 60 * 1000, // Data is cached for 24 hours
    }
  );

  return {
    logoUrl: data ?? null,
    isLoading,
    isError,
    error
  }
};
