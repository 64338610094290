import { stepByStepTypes } from './stepByStepTypes';
import { IvoteHistory, Resolution } from '../../types/ipxyResolutions';
import { IPreviousResponses } from "../../types/previousResponses";

export const setCurrentStep = (currentStep: string) => {
  return {
    type: stepByStepTypes.SET_CURRENT_STEP,
    payload: currentStep,
  };
};

export const setEconf = (econf: string) => {
  return {
    type: stepByStepTypes.SET_ECONF,
    payload: econf,
  };
};

export const addEConfEmail = (econfEmail: string) => {
  return {
    type: stepByStepTypes.ADD_ECONF_EMAIL,
    payload: econfEmail,
  };
};

export const isEconfEmailValid = (value: boolean) => {
  return {
    type: stepByStepTypes.ECONF_EMAIL_VALID,
    payload: value,
  };
};

export const econfVerifiedEmail = (value = '') => {
  return {
    type: stepByStepTypes.ADD_ECONF_VERIFIED_EMAIL,
    payload: value,
  };
};

export const setAdditionalRequests = (
  value: { rqid: string; rqchoid: string }[]
) => {
  return {
    type: stepByStepTypes.SET_ADDITIONAL_REQUESTS,
    payload: value,
  };
};

export const setResponseType = (value: string) => {
  return {
    type: stepByStepTypes.SET_RESPONSE_TYPE,
    payload: value,
  };
};

export const setIProxyResolutions = (value: any) => {
  return {
    type: stepByStepTypes.SET_IPROXY_RESOLUTIONS,
    payload: value,
  };
};

export const setShareholderProxy = (value: any) => {
  return {
    type: stepByStepTypes.SET_SHAREHOLDER_PROXY_DATA,
    payload: value,
  };
};

export const setShareholderFormIsValid = (value: boolean) => {
  return {
    type: stepByStepTypes.SET_SHAREHOLDER_FORM_VALIDATION,
    payload: value,
  };
};

export const setIsHistoryPresent = (value: boolean) => {
  return {
    type: stepByStepTypes.SET_IS_HISTORY_PRESENT,
    payload: value,
  };
};

export const fetchResponseHistory = (accessToken: string, allResolutions: Resolution[]) => {
  return async (dispatch: any) => {
    try {
      const url = `${process.env.REACT_APP_GMM_API_BASE_URL}${process.env.REACT_APP_GMM_API_INFO}${process.env.REACT_APP_GMM_API_USER_RESPONSES}`;
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${accessToken}`,
        },
      });

      const data = await response.json();

      const respHist: IPreviousResponses = data;
      const respCode = respHist?.rsptyp;
      const addReqHist = respHist?.addreq;

      if (respCode) {
        dispatch(setIsHistoryPresent(true));
      }

      if (respCode === 'ADMT') {
        dispatch(setResponseType(respCode));
        dispatch(setEconf('via_post'));
      }

      if (respCode === 'EADM') {
        dispatch(setResponseType(respCode));
        dispatch(setEconf('via_email'));
        if (respHist?.econfEmail || respHist?.econfemail) {
          const econfEmail = respHist?.econfEmail ?? respHist?.econfemail;
          dispatch(isEconfEmailValid(true));
          if (econfEmail) {
            dispatch(addEConfEmail(econfEmail));
          }
          dispatch(econfVerifiedEmail(econfEmail));
        }
      }

      if (respCode === 'SPXY') {
        const pxyadr = respHist?.pxyadr;
        // Convert back to camel case from response
        if (pxyadr?.representedby) {
          pxyadr.representedBy = pxyadr.representedby;
          delete pxyadr.representedby;
        }
        dispatch(setResponseType(respCode));
        dispatch(setShareholderProxy(respHist?.pxyadr));
      }

      if (respCode === 'IPXY') {
        const resolutionsWithVote = allResolutions.filter(
          (e) => e.rsltyp === 'VOTE'
        );

        const filteredSelectionHistory = respHist.votperrsln.filter(
          (vote: IvoteHistory) =>
            resolutionsWithVote.some((res) => vote.rslnid === res.rslnid)
        );

        respHist.votperrsln = filteredSelectionHistory.map(
          (vote: IvoteHistory) => {
            const selectable = resolutionsWithVote.find(
              (res) => res.rslnid === vote.rslnid
            );
            vote.rslnseq = selectable?.rslnseq;
            return vote;
          }
        );

        dispatch(setIProxyResolutions(respHist));
        dispatch(setResponseType(respCode));
      }

      if (respCode === 'CPXY') {
        dispatch(setResponseType(respCode));
      }

      if (addReqHist) {
        dispatch(setAdditionalRequests(addReqHist));
      }
    } catch (error) {
      return null;
      // console.error(error)
    }
  }
};
