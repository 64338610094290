import { AuthContextProps } from "oidc-react";
import { IAuthCustomClaims } from "../../types/authCustomClaims";

export function oidcReactCustomClaims(authContext: AuthContextProps): IAuthCustomClaims | undefined {
  if (!authContext.userData?.profile) return undefined;

  return {
    ...authContext.userData.profile,
    ...authContext.userData.profile as unknown as IAuthCustomClaims,
  };
}
