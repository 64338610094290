import { styled } from '@mui/system';
import Button from '@mui/material/Button';

const StyledButton = styled(Button)({
  transition: 'color .01s',  // fix for Safari bug https://github.com/mui-org/material-ui/issues/26251
  backgroundColor: 'var(--primary-color)',
  borderRadius: 12,
  border: 0,
  color: '#fff',
  '&.Mui-disabled': {
    backgroundColor: '#CCCCCC',
  },
  textTransform: 'none',
});

export default StyledButton;
