import { hybridAuth } from "../api/hybridAuth";
import useAuth from "../auth/oidc-react/useAuth";
import { useEffect, useState } from "react";
import { useMeetingDetails } from "./useMeetingDetails";
import { useFetchCurrentTime } from "./useFetchCurrentTime";

interface IUseVirtualMeetingCheckResponse {
  isVirtualMeetingAllowed: boolean,
  isVirtualMeetingStarted: boolean,
  virtualAccessTime: number | null,
  isShowingCountdown: boolean,
  error: string | null,
  isLoading: boolean
}

export const useVirtualMeetingCheck = () => {
  const [virtualMeetingState, setVirtualMeetingState] = useState<IUseVirtualMeetingCheckResponse>({
    isVirtualMeetingAllowed: false,
    isVirtualMeetingStarted: false,
    virtualAccessTime: null,
    isShowingCountdown: false,
    error: null,
    isLoading: false
  });
  const {getAccessToken} = useAuth();
  const {data: meetingDetails, isLoading: isMeetingDetailsLoading} = useMeetingDetails();
  const virtualMeetingAccessTime = meetingDetails?.virtMeetingStart;
  const voteMode = meetingDetails?.votemod;
  const admtnDeadline = meetingDetails?.admtDeadline;
  const reprDeadline = meetingDetails?.reprDeadline;

  // Check if virtual meeting is enabled
  useEffect(() => {
    if (voteMode && (voteMode === 'HYBD' || voteMode === 'VRTL')) {
      setVirtualMeetingState(prevState => ({...prevState, isVirtualMeetingAllowed: true}));
    }
  }, [voteMode]);

  const {currentTime} =  useFetchCurrentTime();

  useEffect(() => {
    const fetchTime = () => {

      if (virtualMeetingState.isVirtualMeetingAllowed && virtualMeetingAccessTime && currentTime) {
        const isVirtualMeetingStarted = currentTime > virtualMeetingAccessTime;

        setVirtualMeetingState(prevState => ({
          ...prevState,
          virtualAccessTime: virtualMeetingAccessTime,
          isVirtualMeetingStarted
        }));
      }

      // Check if showing countdown required
      if (
        virtualMeetingState.isVirtualMeetingAllowed &&
        !virtualMeetingState.isVirtualMeetingStarted &&
        virtualMeetingAccessTime &&
        currentTime
      ) {
        let isShowingCountdown = false;


        /**
         * Determines if the admission deadline has passed.
         *
         * @param {Date} admtnDeadline - The admission deadline.
         * @param {Date} currentTime - The current time.
         * @returns {boolean} - Returns `true` if the admission deadline has passed, otherwise `false`.
         */
        const isAdmtnDeadlinePast = admtnDeadline ? admtnDeadline < currentTime : true;
        /**
         * Checks whether the representation deadline has passed.
         *
         * @param {number|undefined} reprDeadline - The representation deadline, specified as a timestamp.
         * @param {number} currentTime - The current time, specified as a timestamp.
         * @returns {boolean} - Returns true if the representation deadline has passed or if no deadline is specified, otherwise returns false.
         */
        const isReprDeadlinePast = reprDeadline ? reprDeadline < currentTime : true;

        if(currentTime && (isAdmtnDeadlinePast && isReprDeadlinePast) && currentTime < virtualMeetingAccessTime){
          isShowingCountdown = true;
        }
        setVirtualMeetingState(prevState => ({ ...prevState, isShowingCountdown }));
      }
    }

    if (!isMeetingDetailsLoading) {
      fetchTime();
    }

  }, [virtualMeetingState.isVirtualMeetingAllowed, virtualMeetingAccessTime, isMeetingDetailsLoading, currentTime, admtnDeadline, reprDeadline, virtualMeetingState.isVirtualMeetingStarted]);

  const startVirtualMeeting = async () => {
    const accessToken = await getAccessToken();

    if (!accessToken) {
      // eslint-disable-next-line
      throw ('Missing access token.')
    }

    if (virtualMeetingState.isVirtualMeetingAllowed && virtualMeetingState.isVirtualMeetingStarted && accessToken) {
      try {
        setVirtualMeetingState(prevState => ({
          ...prevState,
          error: null,
          isLoading: true
        }));
        await hybridAuth(accessToken);
      } catch (e: any) {
        setVirtualMeetingState(prevState => ({
          ...prevState,
          error: e ?? '',
          isLoading: false
        }));
      }
    }
  }

  return {...virtualMeetingState, startVirtualMeeting};
}
