import React from 'react';
import Box from '@mui/material/Box';
import { Container } from '@mui/material';
import Grid from '@mui/material/Grid';
import LanguageSelector from '../languageSelector';
import useFooterStlyes from './footerStyles';
import List from '@mui/material/List';
import ListItemLink from '../listItemLink';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import { useMeetingDetails } from "../../hooks/useMeetingDetails";
import { useTranslation } from "react-i18next";

interface IProps {
  extendedFooter: boolean;
}

function Footer({ extendedFooter }: Readonly<IProps>) {
  const footerClasses = useFooterStlyes();

  const { data: meetingDetails } = useMeetingDetails();
  const { t } = useTranslation();

  const publications = meetingDetails?.publications?.length;

  return (
    <Box
      width={1}
      bgcolor="common.white"
      component="footer"
      className={clsx(footerClasses.footer, {
        [footerClasses.extendedFooter]: extendedFooter,
      })}
      boxShadow={2}
    >
      <Container>
        <Grid container>
          <Grid item xs={6} md={4}>
            <Box mt={1}>
              <List className={footerClasses.firstRowLinks}>
                <ListItemLink
                  primary={t('menu.event')}
                  to="/#event"
                />
                <ListItemLink
                  primary={t('menu.agenda')}
                  to="/#agenda"
                />
                {publications && (
                  <ListItemLink
                    primary={t('menu.publications')}
                    to="/#publications"
                  />
                )}
              </List>
            </Box>
          </Grid>
          <Grid item xs={6} md={4} className={footerClasses.languageSelector}>
            <LanguageSelector />
          </Grid>
          <Grid item xs={12} md={4} className={footerClasses.secondRow}>
            <Typography component="div">
              <Box mt={3}>
                {t('text.need_help')}
                <Box fontWeight={700} mb={2}>
                    <Link
                      color="inherit"
                      href={`mailto:${t('text.support_email')}`}
                      display="block"
                    >
                      {t('text.support_email')}
                    </Link>
                    <Link
                      color="inherit"
                      href={`tel:${t('text.support_phone')}`}
                      display="block"
                    >
                      {t('text.support_phone')}
                    </Link>
                    <Typography component="div">
                      <Box mt={1}>
                        <Link
                          component={RouterLink}
                          to="/terms-and-conditions"
                          color="inherit"
                        >
                          {t('title.terms_conditions_title')}
                        </Link>
                      </Box>
                    </Typography>
                </Box>
              </Box>
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}

export default Footer;
