import { useQuery } from "@tanstack/react-query";
import { fetchResolutions } from '../api/fetchResolutions';
import { IPXYResolutions, Resolution } from "../types/ipxyResolutions";
import useAuthentication from "../auth/oidc-react/useAuth";

export const useResolutions = () => {
  const { getAccessToken, mtngId } = useAuthentication();

  const { data, isLoading, isError, error } = useQuery<IPXYResolutions | null, Error>(
    ['resolutions', mtngId],
    async () => {
      const token = await getAccessToken();
      if (token) {
        return fetchResolutions(token);
      } else {
        throw new Error('No access token for resolutions call.');
      }
    }, {
      enabled: !!mtngId,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      staleTime: 5 * 60 * 1000, // Data is considered fresh for 5 minutes
      // cacheTime: 24 * 60 * 60 * 1000, // Data is cached for 24 hours
      retry: false
    }
  );

  return {
    allResolutions: data?.resolutions as Resolution[],
    adhocOptions: data?.adhcd as IPXYResolutions['adhcd'],
    isLoading,
    isError,
    error
  };
};
