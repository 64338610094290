import { createTheme } from '@mui/material/styles';

// A custom theme for this app
let theme = createTheme(({
  typography: {
    htmlFontSize: 16,
    fontFamily: [
      'CustomFont',
      'Tahoma',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif'
    ].join(','),
    body1: {
      fontSize: '1rem',
      color: 'inherit',
    },
    body2: {
      fontSize: '1rem',
      color: 'inherit',
    },
    h1: {
      fontSize: '1.5rem',
      fontWeight: 700,
      color: 'inherit',
    },
    h2: {
      fontSize: '1.125rem',
      fontWeight: 700,
      color: 'inherit',
    },
    h3: {
      fontSize: '1.125rem',
      fontWeight: 400,
      color: 'inherit',
    },
  },
  palette: {
    primary: {
      main: '#821E67',
    },
    secondary: {
      main: '#A0BA32',
    },
    background: {
      default: '#EFEEED',
    },
    success: {
      main: '#A0BA32',
    },
    error: {
      main: '#D64545',
    },
  },
  components: {
    MuiContainer: {
      styleOverrides: {
        root: {
          height: '100%',
        },
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 10,
          padding: '10px 32px',
          textTransform: 'none',
        },
        contained: {
          backgroundColor: '#A2A1A1',
          color: '#fff',
          '&:hover': {
            backgroundColor: '#BCBBBB',
          },
          '&.Mui-disabled': {
            backgroundColor: '#CCCCCC',
          },
        },
        containedSecondary: {
          color: '#fff',
          '&.Mui-disabled': {
            backgroundColor: '#CCCCCC',
          },
          '&:hover': {
            backgroundColor: '#B7D53B',
          },
        },
      }
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          color: 'inherit',
          '&.Mui-focused': {
            color: 'inherit',
          },
          '&.Mui-error': {
            color: '#D64545',
          },
        },
      }
    },
    MuiInput: {
      styleOverrides: {
        underline: {
          '&:before': {
            borderBottomColor: 'inherit',
          },
        },
      }
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: '#BCBBBB',
        },
        shrink: {
          color: 'inherit',
        },
      }
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          '&.Mui-error': {
            backgroundColor: '#fff',
            padding: '1px 3px',
          },
        },
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: 12,
          backgroundColor: '#fff',
        },
      }
    },
    MuiBackdrop: {
      styleOverrides: {
        root: {
          backgroundColor: 'rgba(0,0,0,0.8)',
        },
      }
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          color: 'inherit',
        },
      }
    },
    MuiFab: {
      styleOverrides: {
        root: {
          backgroundColor: '#fff',
        },
      }
    },
    MuiListItem: {
      styleOverrides: {
        root: {
          alignItems: 'flex-start',
        },
      }
    },
  },
}));

export default theme;
