import {Box} from "@mui/material";
import CircularProgress from '@mui/material/CircularProgress';
import React from 'react';
import Typography from '@mui/material/Typography';
import useAuth from "../../auth/oidc-react/useAuth";
import Container from "@mui/material/Container";
import StyledButton from "../styledButton/StyledButton";
import { useTranslation } from "react-i18next";
import ExitToAppRoundedIcon from "@mui/icons-material/ExitToAppRounded";

interface IProps {
  error?: string | null
  appbar?: boolean
}

const FullscreenSpinner = ({error, appbar}: IProps) => {
  const { t } = useTranslation();
  const displayMessage = error;

  const { user, signOutRedirect , userLangNoFallback} = useAuth();

  // List of supported languages
  const supportedLanguages = ['en', 'de', 'it', 'fr'];
  const browserLanguage = window.navigator.language.split('-')[0];
  let language = supportedLanguages.includes(browserLanguage) ? browserLanguage : 'de';
  const selectedLang = userLangNoFallback ?? language;

  interface Messages {
    [key: string]: string;
  }

  const messages: Messages = {
    en: "Due to technical problems, access to the website is currently not possible. Please try again in a few minutes. If the problem persists, please contact the support.",
    de: "Aufgrund technischer Schwierigkeiten ist der Zugriff auf die Website momentan nicht möglich. Bitte versuchen Sie es in wenigen Minuten nochmals. Sollte das Problem weiterhin bestehen, kontaktieren Sie bitte den Support.",
    it: "A causa di problemi tecnici, l'accesso al sito web è attualmente impossibile. Si prega di riprovare tra qualche minuto. Se il problema persiste, contatta l'assistenza.",
    fr: "En raison de problèmes techniques, l'accès au site web est actuellement impossible. Veuillez réessayer dans quelques minutes. Si le problème persiste, veuillez contacter l'assistance."
  };

  return (
    <Box
      minHeight="100dvh"
      display="flex"
      alignItems="center"
      sx={{
        backgroundImage: {
          md: 'url("/assets/images/gvote_background_mobile.jpg")', // For 'md' breakpoint and down
          xs: 'url("/assets/images/gvote_background.jpg")' // Default to larger image
        },
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundAttachment: 'fixed',
        backgroundPosition: 'center center',
        marginTop: {
          md: appbar ? '-64px' : 'initial',
          sx: appbar ? '-64px' : 'initial'
        }
      }}
    >
      <Container maxWidth="sm">
        <Box
          boxShadow={1}
          bgcolor="common.white"
          width={1}
          maxWidth={440}
          minHeight={320}
          p={3}
          pt={!displayMessage ? 3 : 0}
          my={3}
          borderRadius="30px"
          display='grid'
        >

          {!displayMessage && (
            <Box sx={{
              flexDirection: 'column',
              alignSelf: 'center',
              justifySelf: 'center',

            }}>
              <CircularProgress/>
            </Box>
          )}

          {displayMessage && (
            <Box sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between'

            }}>

              <Box sx={{
                backgroundImage: "url('/assets/images/govte_logo.svg')",
                backgroundSize: 'contain',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
                height: 140,
                borderRadius: '30px 30px 0 0',
              }}></Box>

              <Typography fontWeight={700}>
                {messages[selectedLang]}
              </Typography>

              <Box sx={{
                marginTop: 4,
                fontWeight: 'bold',
                '& a': {
                  color: '#000',
                  display: 'block',
                },
                '& a:hover': {
                  color: '#821E67',
                }
              }}>
                <a href={`mailto:business.support@computershare.ch`}>
                  business.support@computershare.ch
                </a>
                <a href='tel:+41622057750'>
                  +41 62 205 77 50
                </a>
              </Box>

              {user && (
                <StyledButton
                  sx={{
                    mt: 3
                  }}
                  variant="contained"
                  color="primary"
                  size="large"
                  startIcon={<ExitToAppRoundedIcon />}
                  onClick={() => signOutRedirect()}>
                  {t('button.logout', 'Logout')}
                </StyledButton>
              )}

            </Box>
          )}

        </Box>
      </Container>
    </Box>

  )
}
export default FullscreenSpinner;
