import React from 'react';

interface IProps {
  folderId: string;
}

const FontLoader: React.FC<IProps> = ({ folderId }) => {
  return (
    <style
      dangerouslySetInnerHTML={{
        __html: `
            @font-face {
              font-family: "CustomFont";
              font-weight: 400;
              src: url("https://${process.env.REACT_APP_STORAGE_NAME}.blob.core.windows.net/gmm2shp-uistyle-blob/${folderId}/regular-font.ttf") format('truetype');
            }
             @font-face {
              font-family: "CustomFont";
              font-weight: 700;
              src: url("https://${process.env.REACT_APP_STORAGE_NAME}.blob.core.windows.net/gmm2shp-uistyle-blob/${folderId}/bold-font.ttf") format('truetype');
            }
    `,
      }}
    />
  );
};

export default FontLoader;
