const verifyLoginUrl = `${process.env.REACT_APP_GMM_API_BASE_URL}${process.env.REACT_APP_QR_OTP_VERIFY_ENDPOINT}`;


export const postQrLoginVerification = async (vcode: string, user: string) => {

  const data = JSON.stringify({vcode, user});

  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: data,
  };

  const response = await fetch(verifyLoginUrl, requestOptions);

  if (!response.ok) {
    // If the response is not ok, parse the JSON to get the error details
    const errorResponse = await response.json();
    // Assuming errorResponse contains a status key
    const errorStatus = errorResponse.status;

    if (errorStatus) {
      throw {status: errorStatus, httpStatus: response.status}; // Throw an object with both the custom status and HTTP status
    } else {
      throw {status: 'error', httpStatus: response.status};
    }
  }

  return await response.json(); // Parse the JSON response
}
