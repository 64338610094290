import { useQuery } from "@tanstack/react-query";
import useAuthentication from "../auth/oidc-react/useAuth";
import { fetchBackground } from "../api/fetchBackground";

export const useBackgroundImage = () => {
  const { mtngId } = useAuthentication();

  const { data, isLoading, isError, error } = useQuery<string | null>(
    ['background', mtngId],
    async () => {
      if (mtngId) {
        return fetchBackground(mtngId);
      } else {
        // Handle the case where the token is undefined
        throw new Error('No meeting-id for background call.');
      }
    }, {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      staleTime: 5 * 60 * 1000, // Data is considered fresh for 5 minutes
      cacheTime: 24 * 60 * 60 * 1000, // Data is cached for 24 hours
    }
  );

  return {
    backgroundUrl: data ?? null,
    isLoading,
    isError,
    error
  }
};
