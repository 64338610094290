import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

const useLangSelectorStyles = makeStyles(
  createStyles({
    flagDefault: {
      '& .MuiButton-startIcon': {
        visibility: 'hidden',
      },
      borderRadius: 0,
    },
    flagActive: {
      '& .MuiButton-startIcon': {
        visibility: 'visible',
      },
      '& .MuiButton-label': {
        textDecoration: 'underline',
      },
    },
    label: {
      justifyContent: 'unset',
      paddingLeft: 64,
    },
  })
);

export default useLangSelectorStyles;
