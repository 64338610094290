import React, { useEffect, useRef, useState, Fragment } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { CircularProgress } from '@mui/material';
import Box from '@mui/material/Box';
import ReactCodeInput from 'react-verification-code-input';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import Typography from '@mui/material/Typography';
import useAuth from "../../auth/oidc-react/useAuth";
import { fetchQrLoginToken } from "../../api/fetchQrLoginToken";
import {
  userManager
} from "../../auth/oidc-react/AuthProvider";
import { jwtDecode } from "jwt-decode";
import { IdTokenClaims } from "oidc-client-ts";
import {postQrLoginVerification} from "../../api/postQrLoginVerification";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    bgRoot: {
      height: '100vh',
      width: '100vw',
      padding: '0 10px',
      backgroundImage: 'url("/assets/images/gvote_background.jpg")',
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      backgroundAttachment: 'fixed',
      backgroundPosition: 'center center',
      [theme.breakpoints.down('md')]: {
        backgroundImage: 'url("/assets/images/gvote_background_mobile.jpg")',
      },
    },
    containerRoot: {
      backgroundColor: '#fff',
      maxWidth: 440,
      width: '100%',
      margin: '16px 0',
      borderRadius: 30,
      boxShadow: theme.shadows[2],
      padding: '0 32px 64px 32px',
      fontFamily: 'Tahoma, "Helvetica Neue", Arial, sans-serif',
    },
    containerLogo: {
      backgroundImage: "url('/assets/images/govte_logo.svg')",
      backgroundSize: 'contain',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      height: 140,
      borderRadius: '30px 30px 0 0',
    },
    contentRoot: {
      display: 'flex',
      flexDirection: 'column',
    },
    spinner: {
      textAlign: 'center',
    },
    mfaInstructions: {
      marginBottom: theme.spacing(3),
      whiteSpace: 'pre-line',
      '& > p': {
        fontWeight: 'bold',
      },
    },
    contactInformation: {
      marginTop: theme.spacing(4),
      fontSize: theme.typography.pxToRem(16),
      fontWeight: 'bold',
      '& a': {
        color: '#000',
        display: 'block',
      },
      '& a:hover': {
        color: '#821E67',
      },
    },
    inputError: {
      '& div > input': {
        border: '1px solid #D64545 !important',
      },
    },
    verificationInput: {
      width: 'auto !important',
      '& div > input': {
        border: '1px solid rgba(0, 0, 0, 0.23)',
        color: `${theme.palette.text.primary} !important`,
        fontFamily: 'inherit',
        borderRadius: '12px',
        caretColor: `${theme.palette.text.primary} !important`,
        backgroundColor: '#E6E6E6',
        [theme.breakpoints.down(410)]: {
          width: '35px !important',
          height: '35px !important',
        },
      },
      '& div > input:not(:last-child)': {
        marginRight: theme.spacing(1),
      },
      '& div > input:first-child': {
        borderTopLeftRadius: '12px',
        borderBottomLeftRadius: '12px',
      },
      '& div > input:last-child': {
        borderTopRightRadius: '12px',
        borderBottomRightRadius: '12px',
      },
      '& div > input:focus': {
        borderColor: 'var(--primary-color)',
        borderWidth: '2px',
      },
      '& div > input:focus + input': {
        borderLeft: '1px solid rgba(0, 0, 0, 0.23)',
      },
      '& div > input:hover:not(:focus)': {
        borderWidth: '1px',
        borderColor: 'rgba(0, 0, 0, 0.87)',
      },
    },
  })
);

// A custom hook that builds on useLocation to parse
// the query string for you.
function useQuery() {
  return new URLSearchParams(useLocation().search);
}

interface IQrLoginTranslation {
  contactInformation: {
    mailTo: string;
    tel: string;
    telLink: string;
  };
  requestError: {
    [lang: string]: string;
  };
  instructions: {
    [lang: string]: string;
  };
  verifyError: {
    [lang: string]: string;
  };
  expiredCode: {
    [lang: string]: string;
  };
}

function QrLogin() {
  const classes = useStyles();
  let query = useQuery();
  const { user } = useAuth();
  const tokenID = query.get('id') ?? '';
  const isAuthenticated = user;
  const history = useHistory();
  const lang = useRef(navigator.language?.substring(0, 2) || 'de');

  const [mfaRequired, setMfaRequired] = useState(false);
  const [tokenRequestError, setTokenRequestError] = useState(false);
  const [userId, setUserId] = useState('');
  const [verifyError, setVerifyError] = useState(false);
  const [expiryError, setExpiryError] = useState(false);
  const [qrTranslation, setQrTranslation] = useState<IQrLoginTranslation>({
    contactInformation: {
      mailTo: '',
      tel: '',
      telLink: '',
    },
    requestError: {},
    instructions: {},
    verifyError: {},
    expiredCode: {},
  });

  const errMessage =
    (qrTranslation?.requestError &&
      qrTranslation?.requestError[lang.current]) ||
    'Es gab ein technisches Problem mit dem Anmeldevorgang. Bitte versuchen Sie es später noch einmal.  \n Wenn das Problem weiterhin besteht, wenden Sie sich bitte an den Support.';
  const instructionsMessage =
    (qrTranslation?.instructions &&
      qrTranslation?.instructions[lang.current]) ||
    'Bitte geben Sie den 6-stelligen Bestätigungscode ein, der soeben an Ihre registrierte E-Mail-Adresse gesendet wurde. \n Falls Sie den Code nach 60 Sekunden Wartezeit immer noch nicht erhalten haben, versuchen Sie bitte, sich erneut anzumelden. \n Sollten Sie keinen Zugang zu der registrierten E-Mail-Adresse haben, wenden Sie sich bitte an den Support.';
  const verifyErrorMessage =
    (qrTranslation?.verifyError && qrTranslation?.verifyError[lang.current]) ||
    'Das übermittelte Einmalpasswort ist ungültig.';
  const expiredMessage =
    (qrTranslation?.expiredCode && qrTranslation?.expiredCode[lang.current]) ||
    'Der Code ist abgelaufen, bitte versuchen Sie, sich erneut anzumelden.';

  const loginEasy = async (hash: string) => {
    try {
      const response: any = await fetchQrLoginToken(hash);

      if (response?.mfauth) {
        setMfaRequired(true);
      } else {
        await setUser(response);
      }
    } catch (e) {
      setTokenRequestError(true);
    }
  };

  const verifyLogin = async (vcode: string) => {
    setVerifyError(false);
    setExpiryError(false);
    setTokenRequestError(false);

    try {
      const response = await postQrLoginVerification(vcode, userId);

      await setUser(response); // Use the parsed data
    } catch (error) {
      setMfaRequired(true);

      const e = error as any;
      // Check if the thrown object has a status property
      if (e && e.status) {
        if (e.status === 'expired') {
          setExpiryError(true);
        } else if (e.status === 'invalid' || e.status === 'error') {
          setVerifyError(true);
        } else {
          setTokenRequestError(true);
        }
      } else {
        // Handle other errors (like network issues) here
        setTokenRequestError(true);
      }
    }
  };

  const setUser = async (tokenDetails: any) => {
    const decodedToken: IdTokenClaims = jwtDecode(tokenDetails.id_token);
    const decodedAccessToken: IdTokenClaims = jwtDecode(tokenDetails.access_token);
    const userDetails = {
      access_token: tokenDetails.access_token,
      id_token: tokenDetails.id_token,
      refresh_token: tokenDetails.refresh_token,
      scope: tokenDetails.scope,
      token_type: tokenDetails.token_type,
      expires_at: decodedAccessToken.exp,
      profile: decodedToken,
      session_state: null,
      state: '',
      scopes: ['openid GVOTE:STKE GVOTE:PRXY GVOTE:ADMN GVOTE:GUST'],
      toStorageString: function() {
        // Stringify the user details object, excluding the toStorageString method itself
        return JSON.stringify({ ...this, toStorageString: undefined });
      },
      get expired() {
        // Determine if the session has expired based on the current time and `expires_at`
        return (new Date().getTime() / 1000) >= this.expires_at;
      },
      get expires_in() {
        // Calculate the seconds left until the session expires
        // If the session has expired, this would be negative
        return this.expires_at - (new Date().getTime() / 1000);
      }
    };

    await userManager.storeUser(userDetails);

    window.location.href = '/callback';
  }

  useEffect(() => {
    if (!['de', 'it', 'en', 'fr'].includes(lang.current)) {
      lang.current = 'de';
    }

    const getTranslation = async () => {
      try {
        const response = await fetch(
          `https://${process.env.REACT_APP_STORAGE_NAME}.blob.core.windows.net/gmm2shp-trnsinfo-blob/00-qr-login-translation/qr-login-translation.json`
        );

        if (!response.ok) {
          // If the response is not ok, throw an error to catch it below
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        setQrTranslation(data);
      } catch (e) {
        console.error("Failed to fetch translation:", e);
        // Handle the error accordingly
      }
    };

    getTranslation();
  }, []);

  useEffect(() => {
    if (!tokenID) {
      history.replace('/');
    }

    setUserId(tokenID);

    if (!isAuthenticated) {
      let timerFunc = setTimeout(() => {
        loginEasy(tokenID);
      }, 500);

      return () => clearTimeout(timerFunc);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tokenID]);

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
      className={classes.bgRoot}
    >
      <div className={classes.containerRoot}>
        <div className={classes.containerLogo} />
        <div className={classes.contentRoot}>
          {!mfaRequired && !tokenRequestError && (
            <div className={classes.spinner}>
              {' '}
              <CircularProgress color="primary" />
            </div>
          )}
          {tokenRequestError && (
            <p>
              <b>{errMessage}</b>
            </p>
          )}
          {mfaRequired && (
            <>
              <div className={classes.mfaInstructions}>
                {instructionsMessage?.split('\n').map((value, index) => {
                  return index === 0 ? (
                    <p key={value.slice(0, 10)}>{value}</p>
                  ) : (
                    <Fragment key={value.slice(0, 10)}>
                      <span>{value}</span>
                      <br />
                    </Fragment>
                  );
                })}
              </div>
              <ReactCodeInput
                autoFocus
                fieldHeight={40}
                fieldWidth={40}
                fields={6}
                type={'number'}
                required
                onComplete={(value) => verifyLogin(value)}
                className={clsx(classes.verificationInput, {
                  [classes.inputError]: verifyError,
                })}
              />
              {(verifyError || expiryError) && (
                <Typography component={'span'} color={'error'}>
                  {expiryError ? expiredMessage : verifyErrorMessage}
                </Typography>
              )}
            </>
          )}
          <div className={classes.contactInformation}>
            <a href={`mailto:${qrTranslation.contactInformation.mailTo}`}>
              {qrTranslation.contactInformation.mailTo}
            </a>
            <a href={`tel:${qrTranslation.contactInformation.telLink}`}>
              {qrTranslation.contactInformation.tel}
            </a>
          </div>
        </div>
      </div>
    </Box>
  );
}

export default QrLogin;
