import { Theme } from '@mui/material/styles';

import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

const useFooterStlyes = makeStyles((theme: Theme) =>
  createStyles({
    footer: {
      position: 'relative',
      zIndex: 0,
      paddingBottom: 16,
    },
    extendedFooter: {
      paddingBottom: 86,
      [theme.breakpoints.down(450)]: {
        paddingBottom: 136,
      },
    },
    firstRowLinks: {
      [theme.breakpoints.down(410)]: {
        padding: 0,
        '& span': {
          padding: 0,
        },
      },
    },
    languageSelector: {
      [theme.breakpoints.down(410)]: {
        padding: 0,
        '& span': {
          padding: 0,
        },
      },
    },
    secondRow: {
      [theme.breakpoints.down('md')]: {
        padding: '0 32px',
      },
      [theme.breakpoints.down(410)]: {
        padding: 0,
        '& a': {
          fontSize: '0.95rem',
        },
      },
    },
  })
);

export default useFooterStlyes;
