import { useState, useEffect } from 'react';
import useAuth from '../auth/oidc-react/useAuth';
import { useMeetingDetails } from './useMeetingDetails';
import { fetchCurrentDateTime } from "../api/fetchCurrentDateTime";
import dayjs from "dayjs";

const maxTime = 2147483647; // Maximum delay in milliseconds for setTimeout

type AccessState = {
  isAccessPermitted: boolean;
  formattedAccessTime: string | null;
  isLoading: boolean;
  error: string | null; // Make sure this allows for string or null
};
export const useAccessTimeCheck = () => {
    const [accessState, setAccessState] = useState<AccessState>({
      isAccessPermitted: false,
      formattedAccessTime: null,
      isLoading: true,
      error: null
    });
  const {data: meetingDetails, isError, isLoading: isMeetingDetailsLoading} = useMeetingDetails();
  const {signOutRedirect, getAccessToken} = useAuth();
  const accessTime = meetingDetails?.accessTime;

  useEffect(() => {
    let logoutTimer: ReturnType<typeof setTimeout>;

    const checkAccess = async () => {

      try {
        const accessToken = await getAccessToken();

        if (!accessToken) {
          return null;
        }
        // async function that fetches server time
        const currentServerTime = await fetchCurrentDateTime(accessToken);

        if (isError) {
          throw new Error('Error fetching meeting details');
        }

        if (accessTime != null && currentServerTime != null) {
          const accessTimeEpoch = new Date(accessTime).getTime();
          const isAccessPermitted = currentServerTime >= accessTimeEpoch;
          const formattedAccessTime = (dayjs(accessTime).format('DD-MM-YYYY HH:mm'));


          setAccessState({isAccessPermitted, formattedAccessTime, isLoading: false, error: null});

          if (currentServerTime < accessTimeEpoch) {
            const timeLeft = accessTimeEpoch - currentServerTime;
            if (timeLeft <= maxTime) {
              logoutTimer = setTimeout(window.location.reload, timeLeft);
            } else {
              // If the access time is too far in the future, set a max delay timeout
              logoutTimer = setTimeout(() => {
                // Re-check access by calling the checkAccess function recursively
                checkAccess();
              }, maxTime);
            }
          }
        } else {
          // If meetingDetails is defined but accessTime is not, handle as an error
          setAccessState({
            isAccessPermitted: false,
            formattedAccessTime: null,
            isLoading: false,
            error: 'Access deadline is not defined or cannot determine server time.'
          });
        }
      } catch (error) {
        const message = (error instanceof Error) ? error.message : 'An unknown error occurred in access time check';
        setAccessState({
          isAccessPermitted: false,
          formattedAccessTime: null,
          isLoading: false,
          error: message
        });
      }
    };

    if (!isMeetingDetailsLoading) {
      checkAccess();
    }

    return () => {
      if (logoutTimer) {
        clearTimeout(logoutTimer);
      }
    };
  }, [meetingDetails, isError, signOutRedirect, accessTime, getAccessToken, isMeetingDetailsLoading]);

  return accessState;
};
