import { isSessionStorageSupported } from './isSessionStorageSupported';

export function safelyGetItem<T>(key: string, sanitizer?: (data: any) => T | null): T | null {
  if (!isSessionStorageSupported()) {
    console.error('Session storage is not supported by this browser.');
    return null;
  }

  try {
    const value = sessionStorage.getItem(key);
    const sanitizedValue = value && sanitizer ? sanitizer(value) : value;

    return sanitizedValue as T | null;
  } catch (error) {
    return null;
  }
}

export function getFromSessionStorage<T>(key: string, sanitizer?: (data: any) => T | null): T | null {
  return safelyGetItem<T>(key, sanitizer);
}
