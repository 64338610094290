export const fetchLogo = async (mtngId: string):  Promise<string | null> => {
  const formats = ['jpg', 'png'];
  for (let format of formats) {
    const url = `https://${process.env.REACT_APP_STORAGE_NAME}.blob.core.windows.net/gmm2shp-uistyle-blob/${mtngId}/logo.${format}`;
    const response = await fetch(url);
    if (response.ok) return URL.createObjectURL(await response.blob());
  }
  console.error('No logo was provided.');
  return null;
};
