export const hybridAuth = async (accessToken: string): Promise<void> => {
  try {
    const response = await fetch(`${process.env.REACT_APP_GMM_API_BASE_URL}${process.env.REACT_APP_GMM_API_VM_USER_REG}`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${accessToken}`,
      },
    });

    const data = await response.json();

    // If the response is not ok (i.e., status is not 2xx), throw the error from the body
    // eslint-disable-next-line
    if (!response.ok) throw ('unexpected_error');

    // eslint-disable-next-line
    if (data.body.error) throw (data.body.error);

    // If the response is ok but does not contain a redirect uri
    // eslint-disable-next-line
    if (!data.body?.redirect_uri) throw ("missing_redirect_uri");

    // Redirect the user to the received URI with access token
    window.location.href = data.body.redirect_uri;

  } catch (e) {
    if (e instanceof TypeError && e.message === 'Failed to fetch') {
      // eslint-disable-next-line
      throw ('unexpected_error');
    }
    throw e;
  }
};
