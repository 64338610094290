import { applyMiddleware, combineReducers, createStore } from 'redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import stepByStepReducer from './stepByStep/stepByStepReducer';

const rootReducer = combineReducers({
  stepByStep: stepByStepReducer,
});

const reduxStore = createStore(
  rootReducer,
  composeWithDevTools(applyMiddleware(thunk))
);

export type RootState = ReturnType<typeof rootReducer>;
export type AppDispatch = typeof reduxStore.dispatch;

export default reduxStore;
