import React, { Suspense, useEffect } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { lighten } from '@mui/material';
import { LoginPage } from "./pages/LoginPage";
import QrLogin from './components/qrLogin';
import AuthCallback from "./pages/AuthCallback";
import useAuth from "./auth/oidc-react/useAuth";
import { useMeetingDetails } from "./hooks/useMeetingDetails";
import NewProtectedRoute from "./pages/NewProtectedRoute";
import { useResolutions } from "./hooks/useResolutions";
import { fetchResponseHistory } from "./redux/stepByStep/stepByStepActions";
import { useTranslationFiles } from "./hooks/useTranslations";
import { useTranslation } from "react-i18next";

const ThankYouPage = React.lazy(() => import('./pages/thankyou'));
const TermsAndConditions = React.lazy(
  () => import('./pages/TermsAndConditions')
);
const EventPage = React.lazy(() => import('./pages/event'));
const SettingsPage = React.lazy(() => import('./pages/settingsPage'));
const StepByStepPage = React.lazy(() => import('./pages/setpByStepPage'));

function App() {

  const dispatch = useDispatch();
  const { user, userLang} = useAuth();
  const { allResolutions } = useResolutions();
  const { data: meetingData } = useMeetingDetails();
  const { i18n } = useTranslation();

  useTranslationFiles();

  useEffect(() => {
    if (meetingData?.cmpylang && userLang) {
      // Convert everything to lowercase for case-insensitive comparison
      let lowerCaseShareholderLanguage = userLang.toLowerCase();
      const lowerCaseCompanyLanguages = meetingData.cmpylang.map(lang => lang.toLowerCase());

      const savedLanguage = localStorage.getItem('userLanguage') ?? '';

      if (lowerCaseCompanyLanguages.includes(savedLanguage)) {
        i18n.changeLanguage(savedLanguage);

        // Check if user language is in the company languages array
      } else if (lowerCaseCompanyLanguages.includes(lowerCaseShareholderLanguage)) {
        // User language is in the company languages array
        i18n.changeLanguage(lowerCaseShareholderLanguage);
      } else {
        // User language is not in the array, use the first company language
        const defaultLanguage = lowerCaseCompanyLanguages[0];
        i18n.changeLanguage(defaultLanguage);
      }
    }
  }, [meetingData, i18n, userLang]);


  const mtngclrpri = meetingData?.mtngclrpri ?? '#821E67';
  const mtngclrsec = meetingData?.mtngclrsec ?? '#212121';

  useEffect(() => {
    if (allResolutions) {
      dispatch(
      // @ts-ignore
        fetchResponseHistory(user?.access_token, allResolutions)
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allResolutions]);

  /*
   * onResize and useEffect to counter mobile browsers 100vh behaviour with the url bar
   */
  let onResize = () => {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  };

  useEffect(() => {
    onResize();
    if (window) {
      window.addEventListener('resize', onResize);
    }
    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, []);

  /*
   * Global variables for various Company CSS
   * */
  useEffect(() => {
    if (mtngclrpri && mtngclrsec) {
      document.documentElement.style.setProperty(
        '--primary-color',
        `${mtngclrpri}`
      );
      document.documentElement.style.setProperty(
        '--light-primary-color',
        `${lighten(mtngclrpri, 0.4)}`
      );
      document.documentElement.style.setProperty(
        '--secondary-color',
        `${mtngclrsec}`
      );
      document.documentElement.style.setProperty('color', `${mtngclrsec}`);
    }
  }, [mtngclrpri, mtngclrsec]);

  return (
    <Switch>
      <Route exact path="/qr" component={QrLogin} />
      <Route exact path="/login" component={LoginPage} />
      <Route exact path="/callback" component={AuthCallback} />

      {/* Protected routes */}
      <NewProtectedRoute exact path="/" component={EventPage} />
      <NewProtectedRoute exact path="/settings" component={SettingsPage} />
      <NewProtectedRoute exact path="/terms-and-conditions" component={TermsAndConditions} />
      <NewProtectedRoute path={['/admission', '/proxy']} component={StepByStepPage} />
      <NewProtectedRoute exact path="/thank-you" component={ThankYouPage} />

      {/* Anything unknown redirects to the root */}
      <Route path="*">
        <Suspense fallback={''}>
          <Redirect to="/" />
        </Suspense>
      </Route>
    </Switch>
  );
}

export default App;
