import { ITnCResponse } from "../interfaces";

export async function fetchTermsAndConditionsStatus(accessToken: string): Promise<ITnCResponse> {
  const response = await fetch(`${process.env.REACT_APP_GMM_API_BASE_URL}${process.env.REACT_APP_GMM_API_INFO}${process.env.REACT_APP_GMM_API_TNC}`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
  if (!response.ok) {
    console.error('Fetch T&C status network response was not ok');
  }

  return response.json(); // Parses the JSON response into a JavaScript object
}
