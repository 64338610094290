import { useQuery } from "@tanstack/react-query";
import useAuthentication from "../auth/oidc-react/useAuth";
import { fetchMeetingDetails } from "../api/fetchMeetingDetails";
import { IExtendedMeetingDetails } from "../types/IExtendedMeetingDetails";
import dayjs from "dayjs";

export const useMeetingDetails = () => {
  const {getAccessToken, mtngId} = useAuthentication();

  return useQuery<IExtendedMeetingDetails, Error>(
    ['meetingDetails', mtngId],
    async () => {
      const token = await getAccessToken();
      if (!token || !mtngId) {
        throw new Error('No access token or meeting-id for meeting details call.');
      }
      const meeting = await fetchMeetingDetails(token); // assume this fetches the meeting data
      if (meeting) {
        // Calculate additional deadlines
        return {
          ...meeting,
          accessTime: dayjs(`${meeting?.accessdt}${meeting?.accesstm}`, 'YYYYMMDDHHmm').valueOf(),
          admtDeadline: dayjs(`${meeting?.admtdldt}${meeting?.admtdltm}`, 'YYYYMMDDHHmm').valueOf(),
          reprDeadline: dayjs(`${meeting?.rprsdldt}${meeting?.rprsdltm}`, 'YYYYMMDDHHmm').valueOf(),
          virtMeetingStart: dayjs(`${meeting?.vmaccessdt}${meeting?.vmaccesstm}`, 'YYYYMMDDHHmm').valueOf(),
        };
      } else {
        throw new Error('Meeting details is not available.')
      }
    },
    {
      enabled: !!mtngId, // The query will only run if mtngId is not null or undefined
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      staleTime: 60 * 1000, // Data is considered fresh for 1 minute
      cacheTime: 60 * 1000, // Data is cached for 1 minute
      retry: false,
    }
  );
};
