import {
  Redirect,
  Route,
  RouteComponentProps,
  RouteProps
} from "react-router-dom";
import AppBarWrapper from "../components/appBarWrapper";
import React, { FC, lazy, Suspense } from "react";
import FullscreenSpinner from "../components/loadingScreens/FullscreenSpinner";
import { useMeetingDetails } from "../hooks/useMeetingDetails";
import useAuth from "../auth/oidc-react/useAuth";
import useRoleCheck from "../hooks/useRoleCheck";
import { useAccessTimeCheck } from "../hooks/useAccessTimeCheck";
import useTermsAndConditionsCheck from "../hooks/useTermsAndConditionsCheck";
import { useVirtualMeetingCheck } from "../hooks/useVirtualMeetingCheck";

// Lazy loaded components
const TermsAndConditions = lazy(() => import('./TermsAndConditions'));
const VirtualTermsAndConditions = lazy(() => import("./TermsAndConditions/VirtualTermsAndConditions"));
const UnauthorizedPage = lazy(() => import('./UnauthorizedPage/UnauthorizedPage'));
const NotYet = lazy(() => import('../components/accessRestricted/NotYet'));

interface NewProtectedRouteProps extends RouteProps {
  component: React.ComponentType<any>;
}

const NewProtectedRoute: FC<NewProtectedRouteProps> = ({ component: Component, ...rest }) => {
  const { error: meetingDetailsFetchError } = useMeetingDetails();
  const { user, isShareholder, isLoading: isAuthLoading  } = useAuth();
  const { isRoleValid } = useRoleCheck();
  const { isAccessPermitted, formattedAccessTime, error: accessTimeValidationError, isLoading: accessTimeVerificationIsLoading } = useAccessTimeCheck();
  const { isVirtualMeetingAllowed, isVirtualMeetingStarted, isShowingCountdown, virtualAccessTime, error: virtualMeetingErrorMessage, isLoading: virtualMeetingIsLoading } = useVirtualMeetingCheck();
  const { isGvoteTnCAgreed, isLoading: isTnCLoading } = useTermsAndConditionsCheck(isVirtualMeetingStarted);

  // Only shareholders can navigate freely, the other roles can only see the
  // main page and terms and conditions
  const hasRestrictedAccess = !isShareholder;
  const isUnrestrictedPath = ['/','/terms-and-conditions'].includes(rest.path as string);

  // console.log({
  //     isAuthLoading,
  //   meetingDetailsFetchError,
  //   accessTimeVerificationIsLoading,
  //   isTnCLoading,
  //   // virtualMeetingIsLoading,
  //   accessTimeValidationError,
  // });

  if (!user && !isAuthLoading) {
    return <Redirect to="/login" />;
  }

  if (isAuthLoading ||
    meetingDetailsFetchError ||
    accessTimeVerificationIsLoading ||
    isTnCLoading ||
    virtualMeetingIsLoading ||
    accessTimeValidationError
  ) {
    return <FullscreenSpinner error={meetingDetailsFetchError?.message ?? accessTimeValidationError} />;
  }

  if (!isRoleValid) {
    return (
      <Suspense fallback={<FullscreenSpinner />}>
        <UnauthorizedPage />
      </Suspense>
    );
  }

  if (!isAccessPermitted && formattedAccessTime) {
    return (
      <Suspense fallback={<FullscreenSpinner />}>
        <NotYet formattedAccessTime={formattedAccessTime} />
      </Suspense>
    );
  }

  if (isVirtualMeetingAllowed && isVirtualMeetingStarted) {
    return (
      <Suspense fallback={<FullscreenSpinner/>}>
        <VirtualTermsAndConditions/>
      </Suspense>
    );
  }

  if (virtualMeetingErrorMessage || (isShowingCountdown && virtualAccessTime)) {
    return (
      <Suspense fallback=''>
        <NotYet hybridErrorTranslationKey={virtualMeetingErrorMessage} virtualAccessTime={virtualAccessTime}/>
      </Suspense>
    )
  }


  if (!isGvoteTnCAgreed) {
    return (
      <Suspense fallback={<FullscreenSpinner />}>
        <TermsAndConditions />
      </Suspense>
    );
  }

  const renderComponent = (props: RouteComponentProps) => (
    <AppBarWrapper>
      <Suspense fallback={<FullscreenSpinner appbar={true} />}>
        <Component {...props} />
      </Suspense>
    </AppBarWrapper>
  );

  return (
    <Route {...rest} render={(props) => (
      (!hasRestrictedAccess || isUnrestrictedPath) ? renderComponent(props) :
        <Redirect to="/" />
    )} />
  );
};

export default NewProtectedRoute;
